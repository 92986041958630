import React, { Component } from 'react';
import './About.css';

class About extends Component {
  render() {
    return (
      <div className="about">
        <h1>Edit About component or pages/about.jsx to include your information.</h1>
      </div>
    );
  }
}

export default About;
